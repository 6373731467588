
import React from 'react';
import { Container} from 'react-bootstrap';
import './Section1.css';
import Navcomponent from '../Home/Navcomponent';
import Box from '../../Images/Box.svg';


const Section1 = () => {
  return (
    <div className='Section1'>
    <Navcomponent/>

      <Container fluid className='d-flex flex-column align-items-center  text-left'>
     <div className='Herocontent'>
     <h1 className='d-none d-md-flex' style={{ fontSize: 'clamp(26px, 8vw, 80px)', fontWeight: 600, lineHeight: '120%' }}>We’re <br/>Experienced in<br/> this Field</h1>
     <div className='d-flex flex-column d-md-none'>
                  <h4 style={{ fontSize: 'clamp(26px, 3vw, 50px)', fontWeight: 600 }}>We’re Experienced in <br />this Filed</h4>
                  <h6 style={{ fontSize: 'clamp(16px, 3vw, 26px)', fontWeight: 500, lineHeight: '120%' }}>About URBAN</h6>
      
                    <p style={{ fontSize: 'clamp(16px, 3vw, 20px)' }}>

                        Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore  Ut enim ad minima veniam, quis nostrum exercitationem ullam corporis suscipit laboriosam, nisi ut al Nemo enim ipsam.
                    </p>
     </div>
     <img src={Box} alt='' style={{ position: 'absolute', bottom: '0px', left: '0px' }} />
     </div>
   
    
      </Container>
 

    </div>
    
  );
};

export default Section1;
