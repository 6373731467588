import React from 'react'

import { Container, Row, Col } from 'react-bootstrap';
import './Aboutvideo.css'
import samplevideo from '../../Images/samplevideo.webm';

const Aboutvideo = () => {




    return (
        <div>

            <Container fluid className='Aboutvideo d-flex flex-column align-items-center justify-content-center' style={{ padding: 'clamp(15px, 6vw, 100px)' }}>

                <Row>
                    <Col md={3} className='about-head'>
                        <h1 style={{ width: 'clamp(180px, 40vw, 352px)', fontSize: 'clamp(30px, 5vw, 60px)', fontWeight: 600, lineHeight: '120%', textTransform: 'uppercase', textAlign: 'left' }}>Watch our story here!</h1>


                    </Col>
                    <Col md={9} className='aboutvideo-container'>
                        <video controls className='aboutvideo'>
                            <source src={samplevideo} type="video/mp4" />
                        </video>
                    </Col>
                </Row>






            </Container>

        </div>


    )
}

export default Aboutvideo
