import React from 'react'
import './Aboutcomponent.css'
import { Container, Row, Col, Button } from 'react-bootstrap'

const Aboutcomponent = () => {
    const scrollToContactForm = () => {
        const contactFormElement = document.getElementById('contactForm');

        if (contactFormElement) {
            contactFormElement.scrollIntoView({ behavior: 'smooth' }, { duration: 900 });
        }
    };
    return (
        <Container fluid className='Aboutcomponent d-flex flex-column align-items-center justify-content-start' style={{ padding: 'clamp(15px, 6vw, 100px)' }}>
            <Row className='w-100 mb-5'>
                <Col md={6} >
                    <h6 style={{ fontSize: 'clamp(16px, 3vw, 26px)', fontWeight: 500, lineHeight: '120%' }}>About URBAN</h6>
                    <h4 style={{ fontSize: 'clamp(26px, 3vw, 50px)', fontWeight: 600 }}>We’re Experienced in <br />this Filed</h4>
                    <p style={{ fontSize: 'clamp(16px, 3vw, 20px)' }}>
                        At Urban Structures, we pride ourselves on our expertise in creating captivating interiors and exteriors that redefine modern living.                     </p>
                    <p style={{ fontSize: 'clamp(16px, 3vw, 20px)' }}>
                        With a keen eye for detail and a passion for innovation, we transform ordinary spaces into extraordinary environments that reflect the unique personalities and lifestyles of our clients.
                    </p>
                    <div className="about-button w-auto mb-20px" >
                        <Button style={{ width: "180px", background: "white", color: "black", textAlign: "center", fontFamily: "Metropolis", fontSize: "20px", fontStyle: "normal", fontWeight: 500, lineHeight: "120%" }} onClick={scrollToContactForm}>
                            Enquire
                        </Button>
                    </div>
                </Col>
                <Col md={6} className='boxcontainer' style={{width:"550px)"}}>
                   
                 <div className='Box'>

                    <div className='Box22'>
                    <div className='Boxcontent' >
                    <div className="content" >
                                <div className="number" >23+</div>
                                <div className="text" >Completed<br />Projects</div>
                            </div>
                        </div> 
                        <div className='Boxcontent' >
                        <div className="content" >
                                <div className="number" >6+</div>
                                <div className="text" >Year <br /> Experience</div>
                            </div>
                        </div>  
                    </div>
                    <div className='Box11'>
                    <div className='Boxcontent' >
                    <div className="content" >
                                <div className="number" >15+</div>
                                <div className="text" >Happy<br />Customers</div>
                            </div>
                        
                        </div>  
                    </div>

                 </div>

                </Col>
            </Row>
           
        </Container>
    )
}

export default Aboutcomponent