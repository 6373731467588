import React, { useEffect } from 'react';
import { Container} from 'react-bootstrap';
import './Herocomponent.css';
import Navcomponent from './Navcomponent';

const Herocomponent = () => {
  useEffect(() => { 
    function reveal() {
      var reveals = document.querySelectorAll('.reveal');
      for (var i = 0; i < reveals.length; i++) {
        var windowheight = window.innerHeight;
        var revealtop = reveals[i].getBoundingClientRect().top;
        var revealpoint = 150;
        if (revealtop < windowheight - revealpoint) {
          reveals[i].classList.add('active');
        } else {
          reveals[i].classList.remove('active');
        }
      }
    }

    window.addEventListener('scroll', reveal);


    return () => {
      window.removeEventListener('scroll', reveal);
    };
  }, []);
  return (
    <div className='Herocomponent'>
    <Navcomponent style={{ color: 'white' }} />

      <Container fluid className='d-flex flex-column align-items-center  text-center'>
     <div className='Herocontent reveal'>
     <h6 style={{ fontSize: 'clamp(16px, 5vw, 30px)',  fontWeight: 600, lineHeight: '120%'  }}>A Cozy Space for Living</h6>
     <h1 style={{ fontSize: 'clamp(26px, 8vw, 110px)', fontWeight: 600, lineHeight: '120%' }}>URBAN STRUCTURES</h1>
     <h4 style={{ fontSize: 'clamp(20px, 5vw, 70px)',  fontWeight: 600, lineHeight: '120%' }}>& CLOSETS</h4>
     </div>
     <div className='rectangle position-absolute bottom-0 w-100 p-0 m-0'>
        
        </div>
    
      </Container>
 

    </div>
    
  );
};

export default Herocomponent;
