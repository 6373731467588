import React, { useRef, useState } from 'react';
import { Container, Row, Col } from 'react-bootstrap';
import './Contactform.css';
import Box from '../../Images/Rectangles.png';
import Arrow from '../../Images/arrowright.svg';
import emailjs from '@emailjs/browser';

const Contactform = () => {
    const form = useRef();
    const [isSuccess, setIsSuccess] = useState(false);
    const [isFormInvalid, setIsFormInvalid] = useState(false);

    const sendEmail = (e) => {
        e.preventDefault();

        const allInputs = form.current.elements;
        for (let i = 0; i < allInputs.length; i++) {
            if (allInputs[i].type !== 'submit' && allInputs[i].value.trim() === '') {
                setIsFormInvalid(true);
                return;
            }
        }

        emailjs.sendForm('service_ml9rq1x', 'template_w2ztrnf', form.current, '4w1EhflRbnFDOwa-z')
            .then((result) => {
                console.log(result.text);
                console.log("message sent");
                setIsSuccess(true);
                setIsFormInvalid(false); 
            }, (error) => {
                console.log(error.text);
            });
    };

    const closeAlert = () => {
        setIsSuccess(false);
        setIsFormInvalid(false);
    };

    return (
        // <Container fluid id='contactForm' className='Contactform p-0 m-0'>
        //     <Row className='w-100 m-0 p-0'>
        //         <Col lg={6} className='Contactcol1 d-flex align-items-center justify-content-center'>
        //             <img src={Box} alt='' style={{ position: 'absolute', top: '0px', left: '0px' }} />
        //             <h1>We'll keep the <br /> lines of <br /> communication <br /> open.</h1>
        //         </Col>

        //         <Col lg={6} className='Contactcol2 p-0'>
        //             <div className='col2form'>
        //                 <h6>Connect With Us</h6>
        //                 <form className='form' ref={form} onSubmit={sendEmail}>
        //                     <div className="mb-5">
        //                         <input type="text" className="form-control" id="fullName" name="fullName" placeholder="Name" />
        //                     </div>

        //                     <div className="mb-5">
        //                         <input type="email" className="form-control" id="email" name="email" placeholder="Email" />
        //                     </div>

        //                     <div className="mb-5">
        //                         <input type="tel" className="form-control" id="phone" pattern="[0-9]{10}"  name="phone" placeholder="Phone" />
        //                     </div>

        //                     <div className="mb-5">
        //                         <textarea className="form-control" id="message" name="message" rows="1" placeholder="Message"></textarea>
        //                     </div>

        //                     <div className="d-flex justify-content-end">
        //                         <button type="submit" className="Submitbutton bg-black">Submit <span><img src={Arrow} className='px-2' alt='' /></span></button>
        //                     </div>
        //                 </form>
        //                 {isSuccess && (
        //                     <div className="alert  alert-success d-flex mt-3">
        //                         Message sent successfully!
        //                         <button type="button" className="close close-button" onClick={closeAlert}>
        //                             <span aria-hidden="true">&times;</span>
        //                         </button>
        //                     </div>
        //                 )}
        //                 {isFormInvalid && (
        //                     <div className="alert alert-danger d-flex mt-3">
        //                         Please fill in all the fields.
        //                         <button type="button" className="close close-button" onClick={closeAlert}>
        //                             <span aria-hidden="true">&times;</span>
        //                         </button>
        //                     </div>
        //                 )}
        //             </div>
        //         </Col>
        //     </Row>
        // </Container>

            <Container fluid className='Contactform p-0 m-0' id='contactForm'>
                <iframe 
                title="Visme Form"
                src="https://forms.visme.co/formsPlayer/rx9z9ero-usc-contact-form" 
                width="100%" 
                height="900px" 
                frameborder="0" 
                allowfullscreen
                ></iframe>
            </Container>
    );
};

export default Contactform;
