import React from 'react';
import './Maincontent.css';
import close from '../../Images/Projectdetailsclose.svg'
import { useLocation, useNavigate } from 'react-router-dom';
import data from '../api/Data';

const ProjectDetails = () => {
  const location = useLocation();
  const navigate = useNavigate();
  const queryParams = new URLSearchParams(location.search);
  const id = queryParams.get('id');
  console.log("data===",data)

  const handleCloseIconClick = () => {
    navigate('/Project');
  };

  // Check if data is available
  if (!data || data.length === 0) {
    return <div>Loading...</div>;
}

  // Find category_items based on the id
  const selectedItem = data.find(item => item.id === parseInt(id));
  const selectedCategoryItems = selectedItem ? selectedItem.category_items : [];
  console.log("selectedCategoryItems===",selectedCategoryItems.map((item) =>(item.Image1)))
  if (!selectedItem) {
      navigate.goBack();
      return null;
  }

  
  return (
    <div className="container-fuild ">
      <div className="img-fluid z-2 w-100 d-flex d-lg-none justify-content-end position-fixed right-0 " onClick={() => handleCloseIconClick()}>
      <img src={close} alt='close' style={{cursor:'pointer', padding:'20px'}}/></div>
      <div className="img-fluid d-lg-flex z-2 w-100 d-none justify-content-end position-fixed right-0" onClick={() => handleCloseIconClick()}>
        <img src={close} alt='close' style={{cursor:'pointer',padding:'30px'}}/>
      </div>
      {selectedCategoryItems.map((item,index)=>(
        <div className="row w-100">
            <div className="col-md-6 mb-3 position-relative" key={index}>
              <img src={item.Image1} alt="ImageDescription" className="img-fluid" />
                  <div className="hover-content">
                    <div className='item'>
                      <div className='title1'>{item.title1}</div>
                      <div className='title_name1'>{item.title_name1}</div>
                        <div className='contents1 mt-4 '>{item.contents1}
                        <div className='contents2 mt-2 mb-10'>{item.contents2}</div></div>
                        <div className='longtext1'>
                        {item.longtext1}
                        </div>
                      <div className='longtext2'>
                      {item.longtext2}
                      </div>
                </div>  
              </div>
            </div>
          </div>
      ))} 
  </div>
   
  )
}

export default ProjectDetails