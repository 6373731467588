
import React from 'react';
import { Container } from 'react-bootstrap';
import './Servicecomponent.css';
import S1 from '../../Images/Home/Architecture.jpg';
import S2 from '../../Images/Home/Residential.jpg';
import S3 from '../../Images/Home/3DModelling.jpg';
import S4 from '../../Images/Home/InteriorExterior.jpg';
import L1 from '../../Images/L1.svg';
import L2 from '../../Images/L2.svg';
import L3 from '../../Images/L3.svg';
import L4 from '../../Images/L4.svg';
import Box from '../../Images/Rectangles.png';

const cardDetails = [
  {
    bgImage: S1,
    Square: Box,
    imgSrc: L1,
    title: "Architecture",
    description: "From studio apartments to luxury penthouses, we tailor our designs to meet the diverse needs of modern urban dwellers.",
    index: 0
  },
  {
    bgImage: S2,
    Square: Box,
    imgSrc: L2,
    title: "Residential",
    description: "We believe that where you live should reflect who you are. Our residential development projects are a testament to our commitment to creating communities that foster a sense of belonging and pride.",
    index: 1
  },
  {
    bgImage: S3,
    Square: Box,
    imgSrc: L3,
    title: "3D Modelling",
    description: "In addition to our expertise in interior and exterior design, Urban Structures offers comprehensive 3D modeling services. Our team of skilled artists and technicians utilizes state-of-the-art software and techniques to bring architectural concepts to life with stunning realism",
    index: 2
  },
  {
    bgImage: S4,
    Square: Box,
    imgSrc: L4,
    title: "Interior & Exterior",
    description: "From concept to completion, our team of talented designers works closely with clients to bring their vision to life. ",
    index: 3
  }
];

const Card = ({ bgImage, Square, imgSrc, title, description, index }) => {
  const leftPosition = `${index * 25}%`;

  return (
    <div className="card">
      <img className="background-image" src={bgImage} alt="Background" />
      <div className="background-overlay"></div>
      <img className="Square" src={Square} alt={title} style={{ left: leftPosition }} />

      <img className="imgSrc" src={imgSrc} alt={title} />
      <div className='cardcontent'>
        <h5 className='title'>{title}</h5>
        <p className='description'> {description}</p>
      </div>
    </div>
  );
};

const Servicecomponent = () => {
  return (
    <Container fluid className="Servicecomponent d-flex ">
      <h1 className='my-lg-5' style={{ fontSize: 'clamp(25px, 5vw, 60px)', fontWeight: 600, lineHeight: '120%', textTransform: 'uppercase' }}>What We Do?</h1>
      <div className="service-container  bg-transparent mt-lg-5 p-0 m-0">
        {cardDetails.map((card, index) => (
          <Card key={index} {...card} />
        ))}
      </div>
    </Container>
  );
};

export default Servicecomponent;
