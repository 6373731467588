import image1 from '../../Images/Project/Rectangle1.jpg'
import image2 from '../../Images/Project/Rectangle2.jpg'
import image3 from '../../Images/Project/Rectangle3.jpg'
import image4 from '../../Images/Project/Rectangle4.jpg'

import image5 from '../../Images/Rectangle1.png'
import image6 from '../../Images/Rectangle2.png'
import image7 from '../../Images/Rectangle3.png'
import image8 from '../../Images/Rectangle4.png'

const Data =[
    {
        "id": 1,
        "title": "Modern & A Cozy Space for Living",
        "title_name": "20th December 2023, Jane Cooper, IN",
        "Image": image1,
        "category": 'Architecture',
        "category_items": [
                {
                    "id": 1,
                    "title1": "Modern & A Cozy Space for Living",
                    "title_name1": "20th December 2023, Jane Cooper, IN",
                    "contents1": "Area : 500 sqm",
                    "contents2": "Location : Bangalore",
                    "longtext1": "Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore Ut enim ad minima veniam, quis nostrum exercitationem ullam corporis suscipit laboriosam, nisi ut al Nemo enim ipsam.",
                    "longtext2": "Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore Ut enim ad minima veniam,",
                    "Image1": image5
                },
                {
                    "id": 2,
                    "title1": "Modern & A Cozy Space for Living",
                    "title_name1": "20th December 2023, Jane Cooper, IN",
                    "contents1": "Area : 500 sqm",
                    "contents2": "Location : Bangalore",
                    "longtext1": "Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore Ut enim ad minima veniam, quis nostrum exercitationem ullam corporis suscipit laboriosam, nisi ut al Nemo enim ipsam.",
                    "longtext2": "Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore Ut enim ad minima veniam,",
                    "Image1": image6
                },
                {
                    "id":3,
                    "title1":"Modern & A Cozy Space for Living",
                    "title_name1":"20th December 2023, Jane Cooper, IN",
                    "contents1":"Area : 500 sqm",
                    "contents2":"Location : Bangalore",
                    "longtext1":" Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore  Ut enim ad minima veniam, quis nostrum exercitationem ullam corporis suscipit laboriosam, nisi ut al Nemo enim ipsam.",
                    "longtext2":" Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore  Ut enim ad minima veniam,",
                    "Image1": image7,
                },
                {
                    "id":4,
                    "title1":"Modern & A Cozy Space for Living",
                    "title_name1":"20th December 2023, Jane Cooper, IN",
                    "contents1":"Area : 500 sqm",
                    "contents2":"Location : Bangalore",
                    "longtext1":" Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore  Ut enim ad minima veniam, quis nostrum exercitationem ullam corporis suscipit laboriosam, nisi ut al Nemo enim ipsam.",
                    "longtext2":" Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore  Ut enim ad minima veniam,",
                    "Image1": image8,
                },
                {
                    "id":5,
                    "title1":"Modern & A Cozy Space for Living",
                    "title_name1":"20th December 2023, Jane Cooper, IN",
                    "contents1":"Area : 500 sqm",
                    "contents2":"Location : Bangalore",
                    "longtext1":" Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore  Ut enim ad minima veniam, quis nostrum exercitationem ullam corporis suscipit laboriosam, nisi ut al Nemo enim ipsam.",
                    "longtext2":" Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore  Ut enim ad minima veniam,",
                    "Image1": image5,
                },
        ]
    },
    {
        "id": 2,
        "title": "Modern Kitchen & A Cozy Space for Living",
        "title_name": "20th December 2023, Jane Cooper, IN",
        "Image": image3,
        "category": 'InteriorExterior',
        "category_items": [
                {
                    "id": 1,
                    "title1": "Modern & A Cozy Space for Living",
                    "title_name1": "20th December 2023, Jane Cooper, IN",
                    "contents1": "Area : 500 sqm",
                    "contents2": "Location : Bangalore",
                    "longtext1": "Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore Ut enim ad minima veniam, quis nostrum exercitationem ullam corporis suscipit laboriosam, nisi ut al Nemo enim ipsam.",
                    "longtext2": "Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore Ut enim ad minima veniam,",
                    "Image1": image5,
                },
                {
                    "id": 2,
                    "title1": "Modern & A Cozy Space for Living",
                    "title_name1": "20th December 2023, Jane Cooper, IN",
                    "contents1": "Area : 500 sqm",
                    "contents2": "Location : Bangalore",
                    "longtext1": "Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore Ut enim ad minima veniam, quis nostrum exercitationem ullam corporis suscipit laboriosam, nisi ut al Nemo enim ipsam.",
                    "longtext2": "Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore Ut enim ad minima veniam,",
                    "Image1": image5,
                },
                {
                    "id":3,
                    "title1":"Modern & A Cozy Space for Living",
                    "title_name1":"20th December 2023, Jane Cooper, IN",
                    "contents1":"Area : 500 sqm",
                    "contents2":"Location : Bangalore",
                    "longtext1":" Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore  Ut enim ad minima veniam, quis nostrum exercitationem ullam corporis suscipit laboriosam, nisi ut al Nemo enim ipsam.",
                    "longtext2":" Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore  Ut enim ad minima veniam,",
                    "Image1": image6,
                },
                {
                    "id":4,
                    "title1":"Modern & A Cozy Space for Living",
                    "title_name1":"20th December 2023, Jane Cooper, IN",
                    "contents1":"Area : 500 sqm",
                    "contents2":"Location : Bangalore",
                    "longtext1":" Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore  Ut enim ad minima veniam, quis nostrum exercitationem ullam corporis suscipit laboriosam, nisi ut al Nemo enim ipsam.",
                    "longtext2":" Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore  Ut enim ad minima veniam,",
                    "Image1": image7,
                },
                {
                    "id":5,
                    "title1":"Modern & A Cozy Space for Living",
                    "title_name1":"20th December 2023, Jane Cooper, IN",
                    "contents1":"Area : 500 sqm",
                    "contents2":"Location : Bangalore",
                    "longtext1":" Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore  Ut enim ad minima veniam, quis nostrum exercitationem ullam corporis suscipit laboriosam, nisi ut al Nemo enim ipsam.",
                    "longtext2":" Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore  Ut enim ad minima veniam,",
                    "Image1": image5,
                },
        ]
    },
    {
        "id": 3,
        "title": "Modern Kitchen & A Cozy Space for Living",
        "title_name": "20th December 2023, Jane Cooper, IN",
        "Image": image4,
        "category": 'Apartments',
        "category_items": [
                {
                    "id": 1,
                    "title1": "Modern & A Cozy Space for Living",
                    "title_name1": "20th December 2023, Jane Cooper, IN",
                    "contents1": "Area : 500 sqm",
                    "contents2": "Location : Bangalore",
                    "longtext1": "Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore Ut enim ad minima veniam, quis nostrum exercitationem ullam corporis suscipit laboriosam, nisi ut al Nemo enim ipsam.",
                    "longtext2": "Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore Ut enim ad minima veniam,",
                    "Image1": image5
                },
                {
                    "id": 2,
                    "title1": "Modern & A Cozy Space for Living",
                    "title_name1": "20th December 2023, Jane Cooper, IN",
                    "contents1": "Area : 500 sqm",
                    "contents2": "Location : Bangalore",
                    "longtext1": "Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore Ut enim ad minima veniam, quis nostrum exercitationem ullam corporis suscipit laboriosam, nisi ut al Nemo enim ipsam.",
                    "longtext2": "Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore Ut enim ad minima veniam,",
                    "Image1": image6
                },
                {
                    "id":3,
                    "title1":"Modern & A Cozy Space for Living",
                    "title_name1":"20th December 2023, Jane Cooper, IN",
                    "contents1":"Area : 500 sqm",
                    "contents2":"Location : Bangalore",
                    "longtext1":" Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore  Ut enim ad minima veniam, quis nostrum exercitationem ullam corporis suscipit laboriosam, nisi ut al Nemo enim ipsam.",
                    "longtext2":" Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore  Ut enim ad minima veniam,",
                    "Image1": image7,
                },
                {
                    "id":4,
                    "title1":"Modern & A Cozy Space for Living",
                    "title_name1":"20th December 2023, Jane Cooper, IN",
                    "contents1":"Area : 500 sqm",
                    "contents2":"Location : Bangalore",
                    "longtext1":" Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore  Ut enim ad minima veniam, quis nostrum exercitationem ullam corporis suscipit laboriosam, nisi ut al Nemo enim ipsam.",
                    "longtext2":" Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore  Ut enim ad minima veniam,",
                    "Image1": image8,
                },
                {
                    "id":5,
                    "title1":"Modern & A Cozy Space for Living",
                    "title_name1":"20th December 2023, Jane Cooper, IN",
                    "contents1":"Area : 500 sqm",
                    "contents2":"Location : Bangalore",
                    "longtext1":" Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore  Ut enim ad minima veniam, quis nostrum exercitationem ullam corporis suscipit laboriosam, nisi ut al Nemo enim ipsam.",
                    "longtext2":" Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore  Ut enim ad minima veniam,",
                    "Image1": image5,
                },
        ]
    },
    {
        "id": 4,
        "title": "Modern & A Cozy Space for Living",
        "title_name": "20th December 2023, Jane Cooper, IN",
        "Image": image2,
        "category": 'Residential',
        "category_items": [
                {
                    "id": 1,
                    "title1": "Modern & A Cozy Space for Living",
                    "title_name1": "20th December 2023, Jane Cooper, IN",
                    "contents1": "Area : 500 sqm",
                    "contents2": "Location : Bangalore",
                    "longtext1": "Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore Ut enim ad minima veniam, quis nostrum exercitationem ullam corporis suscipit laboriosam, nisi ut al Nemo enim ipsam.",
                    "longtext2": "Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore Ut enim ad minima veniam,",
                    "Image1": image5,
                },
                {
                    "id": 2,
                    "title1": "Modern & A Cozy Space for Living",
                    "title_name1": "20th December 2023, Jane Cooper, IN",
                    "contents1": "Area : 500 sqm",
                    "contents2": "Location : Bangalore",
                    "longtext1": "Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore Ut enim ad minima veniam, quis nostrum exercitationem ullam corporis suscipit laboriosam, nisi ut al Nemo enim ipsam.",
                    "longtext2": "Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore Ut enim ad minima veniam,",
                    "Image1": image6,
                },
                {
                    "id":3,
                    "title1":"Modern & A Cozy Space for Living",
                    "title_name1":"20th December 2023, Jane Cooper, IN",
                    "contents1":"Area : 500 sqm",
                    "contents2":"Location : Bangalore",
                    "longtext1":" Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore  Ut enim ad minima veniam, quis nostrum exercitationem ullam corporis suscipit laboriosam, nisi ut al Nemo enim ipsam.",
                    "longtext2":" Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore  Ut enim ad minima veniam,",
                    "Image1": image7,
                },
                {
                    "id":4,
                    "title1":"Modern & A Cozy Space for Living",
                    "title_name1":"20th December 2023, Jane Cooper, IN",
                    "contents1":"Area : 500 sqm",
                    "contents2":"Location : Bangalore",
                    "longtext1":" Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore  Ut enim ad minima veniam, quis nostrum exercitationem ullam corporis suscipit laboriosam, nisi ut al Nemo enim ipsam.",
                    "longtext2":" Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore  Ut enim ad minima veniam,",
                    "Image1": image8,
                },
                {
                    "id":5,
                    "title1":"Modern & A Cozy Space for Living",
                    "title_name1":"20th December 2023, Jane Cooper, IN",
                    "contents1":"Area : 500 sqm",
                    "contents2":"Location : Bangalore",
                    "longtext1":" Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore  Ut enim ad minima veniam, quis nostrum exercitationem ullam corporis suscipit laboriosam, nisi ut al Nemo enim ipsam.",
                    "longtext2":" Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore  Ut enim ad minima veniam,",
                    "Image1": image5,
                },
        ]
    },
]

export default Data ;