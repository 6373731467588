import React, { useState, useEffect } from 'react';
import Projectdetails from './Maincontent'
import { Spinner } from 'react-bootstrap';



const Allpage = () => {

  const [loading, setLoading] = useState(true);

  useEffect(() => {
   
    const fetchData = async () => {
      try {
        await new Promise(resolve => setTimeout(resolve, 2000));
        setLoading(false);
      } catch (error) {
        console.error('Error fetching data:', error);
      }
    };

    fetchData();
  }, []); 

  return (
    <div>
      {loading && (
         <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', height: '100vh' }}>
         <Spinner animation="border" variant="success" style={{width:'100px', height:'100px', borderWidth: '10px'}} />
       </div>
      )}

      {!loading && (
        <div>
          <Projectdetails/>
        </div>
      )}
    </div>
  );
};

export default Allpage;
