import React, { useState, useEffect, useRef } from 'react';
import './Slidecomponent.css';
import p4 from '../../Images/Home/Slide1.jpg';
import p5 from '../../Images/Home/Slide2.jpg';
import p6 from '../../Images/Home/Slide3.jpg';
import p7 from '../../Images/Home/Slide4.jpg';
import p8 from '../../Images/Home/Slide5.jpg';

import { Container, Button } from 'react-bootstrap';

const Slidecomponent = () => {
  const [currentSlide, setCurrentSlide] = useState(1);
  const intervalIdRef = useRef(null);

  useEffect(() => {
    startAutomaticSlide();
    return stopAutomaticSlide;
  }, []);

  const startAutomaticSlide = () => {
    intervalIdRef.current = setInterval(() => {
      setCurrentSlide((prevSlide) => (prevSlide % 5) + 1);
    }, 3000);
  };

  const stopAutomaticSlide = () => {
    clearInterval(intervalIdRef.current);
  };

  const handleSlideChange = (slideNumber) => {
    stopAutomaticSlide();
    setCurrentSlide(slideNumber);
    startAutomaticSlide();
  };

  return (
    <Container className='Slidecomponent d-flex flex-column align-items-center justify-content-start' style={{ padding: 'clamp(15px, 6vw, 100px)' }}>



      <h1 className='my-5' style={{ fontSize: 'clamp(25px, 5vw, 60px)', fontWeight: 600, lineHeight: '120%', textTransform: 'uppercase' }}>What We Did?</h1>
      <div className='imageslide '>
        <div className='slidescroll  bg-transparent'>
          <div className="slider-container">
            <input type="radio" name="slider" id="s1" checked={currentSlide === 1} onChange={() => handleSlideChange(1)} />
            <input type="radio" name="slider" id="s2" checked={currentSlide === 2} onChange={() => handleSlideChange(2)} />
            <input type="radio" name="slider" id="s3" checked={currentSlide === 3} onChange={() => handleSlideChange(3)} />
            <input type="radio" name="slider" id="s4" checked={currentSlide === 4} onChange={() => handleSlideChange(4)} />
            <input type="radio" name="slider" id="s5" checked={currentSlide === 5} onChange={() => handleSlideChange(5)} />

            <label htmlFor="s1" className="slide" id="slide1">
              <img src={p4} alt="p4"  />
              <div className="blur">
             
            </div>
             
            </label>
            <label htmlFor="s2" className="slide" id="slide2">
              <img src={p5} alt="p5" />
              <div className="blur"></div>
            </label>
            <label htmlFor="s3" className="slide" id="slide3">
              <img src={p6} alt="p6" />
              <div className="blur"></div>
            </label>
            <label htmlFor="s4" className="slide" id="slide4">
              <img src={p7} alt="p7" />
              <div className="blur"></div>
            </label>
            <label htmlFor="s5" className="slide" id="slide5">
              <img src={p8} alt="p8" />
              <div className="blur"></div>
            </label>
          </div>
        </div>


      </div>

      {currentSlide === 1 && (
        <div className='slide-content w-100 text-center my-2'>
          <h1>Riverside Residences</h1>
          <p>At Urban Structures, we had the pleasure of designing and developing Riverside Residences, a stunning apartment complex nestled along the scenic riverfront. Each unit was meticulously crafted to maximize space and functionality, offering residents a harmonious blend of modern luxury and urban convenience. </p>
           <Button className='bg-black px-4' style={{fontSize:'20px'}}>View Project</Button>
        </div>
      )}
      {currentSlide === 2 && (
        <div className='slide-content  w-100 text-center my-2'>
          <h1 >Cityscape Towers</h1>
          <p>Cityscape Towers stands as a testament to our prowess in both interior and exterior design. The sleek, contemporary facade of the towers is a striking addition to the city skyline, while the interiors boast elegant finishes and thoughtful layouts.</p>
           <Button className='bg-black px-4' style={{fontSize:'20px'}}>View Project</Button>
        </div>
      )}
      {currentSlide === 3 && (
        <div className='slide-content w-100 text-center my-2'>
          <h1 >Apartments</h1>
          <p>Urban living demands dynamic and adaptable spaces. Our expertise in apartment design ensures that every square foot is maximized for comfort, functionality, and style. From studio apartments to luxury penthouses, we tailor our designs to meet the diverse needs of modern urban dwellers.</p>
           <Button className='bg-black px-4' style={{fontSize:'20px'}}>View Project</Button>
        </div>
      )}
      {currentSlide === 4 && (
        <div className='slide-content w-100 text-center my-2'>
          <h1 >Oakwood Estates</h1>
          <p>Lakeview Retreat is a bespoke luxury home that showcases our expertise in custom house construction. Nestled on the shores of a picturesque lake, this architectural masterpiece seamlessly integrates with its natural surroundings</p>
           <Button className='bg-black px-4' style={{fontSize:'20px'}}>View Project</Button>
        </div>
      )}
      {currentSlide === 5 && (
        <div className='slide-content w-100 text-center my-2'>
          <h1 >House - Sunset Haven</h1>
          <p>Sunset Haven is a custom-designed home that epitomizes luxury and elegance. Perched on a bluff overlooking the ocean, this architectural gem seamlessly blends indoor and outdoor living spaces to create a sense of effortless sophistication. </p>
          <Button className='bg-black px-4' style={{fontSize:'20px'}}>View Project</Button>
        </div>
      )}

    </Container>
  );
};

export default Slidecomponent;
