import React, { useState, useEffect } from 'react';
import AboutUs from './Maincontent';
import Footer from '../Footer';

import { Spinner } from 'react-bootstrap';
import Faqcomponent from '../Home/Faqcomponent';
import Contactform from '../Home/Contactform';


const Allpage = () => {
  const [loading, setLoading] = useState(true);


  useEffect(() => {
    const fetchData = async () => {
      try {
        await new Promise(resolve => setTimeout(resolve, 2000));
        setLoading(false);
      } catch (error) {
        console.error('Error fetching data:', error);
      }
    };

    fetchData();
  }, []);

  return (
    <div>
      {loading && (
        <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', height: '100vh' }}>
          <Spinner animation="border" variant="success" style={{ width: '100px', height: '100px', borderWidth: '10px' }} />
        </div>
      )}

      {!loading && (
        <div>
       
         
          <AboutUs />
          <Faqcomponent/>
          <Contactform/>
          <Footer />
        </div>
      )}
    </div>
  );
};

export default Allpage;
