import React, { useState, useEffect, useRef } from 'react';
import './Content.css';
import p4 from '../../Images/T1.png';
import p5 from '../../Images/T2.png';
import p6 from '../../Images/T3.png';
import p7 from '../../Images/T4.png';
import p8 from '../../Images/T5.png';
import Right from '../../Images/Right.png';
import Left from '../../Images/Left.png'
import { Container, Row, Col } from 'react-bootstrap';



const Content = () => {
  const [currentTeam, setCurrentTeam] = useState(1);
  const intervalIdRef = useRef(null);

  useEffect(() => {
    startAutomaticTeam();
    return stopAutomaticTeam;
  }, []);

  const startAutomaticTeam = () => {
    intervalIdRef.current = setInterval(() => {
      setCurrentTeam((prevTeam) => (prevTeam % 5) + 1);
    }, 300000);
  };



  const stopAutomaticTeam = () => {
    clearInterval(intervalIdRef.current);
  };

  const handleTeamChange = (TeamNumber) => {
    stopAutomaticTeam();
    setCurrentTeam(TeamNumber);
    startAutomaticTeam();
  };

  const teamData = [
    {
      id: 1,
      name: 'Team Member 1',
      role: 'Founder',
    },
    {
      id: 2,
      name: 'Team Member 2',
      role: 'President',
    },
    {
      id: 3,
      name: 'Team Member 3',
      role: 'CEO',
    },
    {
      id: 4,
      name: 'Team Member 4',
      role: 'Manager',
    },
    {
      id: 5,
      name: 'Team Member 5',
      role: 'Team Lead',
    },
  ];



  return (
    <Container fluid className='Teamcomponent d-flex flex-column align-items-center justify-content-center' style={{ padding: 'clamp(15px, 6vw, 100px)' }}>
      <Row className='Teamrow p-0 m-0 w-100'>

        <Col lg={4} className='Teamcol1 d-flex flex-column align-content-lg-start'>
          <h1 className='my-5' style={{ fontSize: 'clamp(25px, 5vw, 60px)', fontWeight: 600, lineHeight: '120%', textTransform: 'uppercase', textAlign: 'left' }}>Meet Our Team</h1>
          <div className='Team-Data d-lg-flex d-none'>
            {teamData.map((teamMember) => (
              currentTeam === teamMember.id && (
                <div key={teamMember.id} className={`Team-content w-100 ${teamMember.id === 3 ? 'text-center' : 'text-left'} my-2`}>
                  <h2>{teamMember.name}</h2>
                  <p>{teamMember.role}</p>
                </div>

              )
            ))}</div>
        </Col>
        <Col lg={8} className='Teammembers'>
          <div className='icons d-flex justify-content-end'>
            <div className="prev-icon" onClick={() => handleTeamChange((currentTeam - 2 + 5) % 5 + 1)}>
              <span><img className='left' src={Left} alt='' /></span>

            </div>


            <div className="next-icon mt-lg-5 mt-4" onClick={() => handleTeamChange(currentTeam % 5 + 1)}>

              <span><img className='right' src={Right} alt='' />
              </span>
            </div> </div>
          <div className='TeammemberTeam'>
            <div className='Teammemberscroll  bg-transparent'>
              <div className="Teammember-container justify-content-center">
                <input type="radio" name="Teammember" id="s1" checked={currentTeam === 1} onChange={() => handleTeamChange(1)} />
                <input type="radio" name="Teammember" id="s2" checked={currentTeam === 2} onChange={() => handleTeamChange(2)} />
                <input type="radio" name="Teammember" id="s3" checked={currentTeam === 3} onChange={() => handleTeamChange(3)} />
                <input type="radio" name="Teammember" id="s4" checked={currentTeam === 4} onChange={() => handleTeamChange(4)} />
                <input type="radio" name="Teammember" id="s5" checked={currentTeam === 5} onChange={() => handleTeamChange(5)} />

                <label htmlFor="s1" className="Team" id="Team1">
                  <img src={p4} alt="p4" />


                </label>
                <label htmlFor="s2" className="Team" id="Team2">
                  <img src={p5} alt="p5" />

                </label>
                <label htmlFor="s3" className="Team" id="Team3">
                  <img src={p6} alt="p6" />

                </label>
                <label htmlFor="s4" className="Team" id="Team4">
                  <img src={p7} alt="p7" />

                </label>
                <label htmlFor="s5" className="Team" id="Team5">
                  <img src={p8} alt="p8" />

                </label>
              </div>
            </div>

            <div className='Team-Data d-flex d-lg-none'>
              {teamData.map((teamMember) => (
                currentTeam === teamMember.id && (
                  <div key={teamMember.id} className={`Team-content ${teamMember.id === 3 ? 'text-center' : 'text-left'}`}>
                    <h2>{teamMember.name}</h2>
                    <p>{teamMember.role}</p>
                  </div>

                )
              ))}</div>


          </div>
        </Col>
      </Row>





    </Container>
  );
};

export default Content;
