import React, { useState } from 'react';
import './Faqcomponent.css';
import {  Container } from 'react-bootstrap';
import open from '../../Images/open.svg';
import close from '../../Images/close.svg';

const faqData = [
    {
        question: 'What sets Urban Structures apart from other design firms?',
        answer: 'At Urban Structures, we distinguish ourselves through a combination of experience, innovation, and a client-centric approach. With years of experience in the industry, we bring a wealth of knowledge and expertise to every project',
    },
    {
        question: 'How involved will I be in the design process?',
        answer: 'Your involvement in the design process is crucial to us. From the initial consultation to the final walkthrough, we value open communication and collaboration with our clients every step of the way. We begin by discussing your vision, preferences, and budget to ensure that we are aligned with your goals. ',
    },
    {
        question: ' Can Urban Structures assist with sustainable design practices?',
        answer: "Absolutely. Sustainability is a core principle that guides our design philosophy at Urban Structures. We believe in creating spaces that not only enhance the lives of our clients but also minimize our environmental footprint. Our team is well-versed in sustainable design practices, including energy-efficient building techniques, green materials, and passive design strategies. Whether you're interested in LEED certification or simply reducing your carbon footprint, we can tailor our designs to align with your sustainability goals.",
    },
    {
        question: 'What is the typical timeline for a project with Urban Structures?',
        answer: 'The timeline for each project can vary depending on its size, complexity, and scope. During the initial consultation, we will discuss your project timeline and establish realistic milestones based on your needs and priorities. ',
    },
  

];

const Faqcomponent = () => {
    const [activeIndices, setActiveIndices] = useState([0]);

    const handleAccordionClick = (index) => {
        if (activeIndices.includes(index)) {
            setActiveIndices(activeIndices.filter((i) => i !== index));
        } else {
            setActiveIndices([...activeIndices, index]);
        }
    };

    const isIndexActive = (index) => activeIndices.includes(index);
    const getAccordionIcon = (index) => (isIndexActive(index) ? close : open);

    return (
        <Container fluid className="Faqcomponent" style={{ padding: 'clamp(15px, 6vw, 100px)' }}>

            <h1 className='d-none d-lg-block' >FAQ’s</h1>
            <h1 className='d-block d-lg-none' style={{ textTransform:'uppercase' }}>Frequently Asked questions</h1>
            <p className='mb-5'>Here some of the most commonly asked questions</p>
            <Container className='Faqcontent' >
       
                    <div className="accordion ">
                        {faqData.map((item, index) => (
                            <div key={index} className={`accordion-item ${isIndexActive(index) ? 'active' : ''}`} style={{ border: 'none' }}>
                                <div className="accordion-header" onClick={() => handleAccordionClick(index)}>
                                    <span className="accordion-icon">
                                        <img src={getAccordionIcon(index)} alt="Accordion Icon" />
                                    </span>
                                    {item.question}
                                </div>
                                {isIndexActive(index) && (
                                    <div className="accordion-body"> 
                                        <div className="borderLine"></div> 
                                        <div className="answer"> 
                                            {item.answer}
                                        </div>

                                    </div>
                                )}
                            </div>
                        ))}
                    </div>
               
        </Container>
           
        </Container>
    );
}

export default Faqcomponent;
