
import React from 'react';
import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';


import Allpage from './components/Home';
import Project from './components/Project';
import ProjectDetails from './components/Projectdetails';
import AboutUs from './components/AboutUs';
import { ROUTES } from './components/const/routes';
import NotFound from './components/NotFound';
import './App.css';


const App = () => {
  return (
    <Router>
      <div>
        <Routes>
          <Route path={ROUTES.DASHBOARD} element={<Allpage />} />
          <Route path={ROUTES.PROJECT} element={<Project />} />
          <Route path={ROUTES.PROJECTDETAILS} element={<ProjectDetails />} />
          <Route path={ROUTES.ABOUTUS} element={<AboutUs />} />
          <Route path="*" element={<NotFound />} />
        </Routes>
      </div>
    </Router>
  );
};

export default App;
