import React, { useState, useEffect } from 'react';
import Project from './Maincontent'
import Footer from '../Footer';
// import Header from '../Header';
import { Spinner } from 'react-bootstrap';
import Navcomponent from '../Home/Navcomponent';


const Allpage = () => {
  const [loading, setLoading] = useState(true);
  const headerFontColor = true;
  const activeFontColor = 'black'; 

  useEffect(() => {
   
    const fetchData = async () => {
      try {
        await new Promise(resolve => setTimeout(resolve, 2000));
        setLoading(false);
      } catch (error) {
        console.error('Error fetching data:', error);
      }
    };

    fetchData();
  }, []); 

  return (
    <div>
      {loading && (
         <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', height: '100vh' }}>
         <Spinner animation="border" variant="success" style={{width:'100px', height:'100px', borderWidth: '10px'}} />
       </div>
      )}

      {!loading && (
        <div>
          <style>
            {`
              .navbar-nav a.nav-link.active {
                color: ${activeFontColor};
              }
            `}
          </style>
          <Navcomponent headerFontColor={headerFontColor} activeFontColor={activeFontColor} />
          <Project />
          <Footer />
        </div>
      )}
    </div>


  );
};

export default Allpage;
