import React from 'react'
import Herocomponent from './Herocomponent'
import Aboutcomponent from './Aboutcomponent'
import Videocomponent from './Videocomponent'
import Servicecomponent from './Servicecomponent'
import Faqcomponent from './Faqcomponent'
import Contactform from './Contactform'
import Slidecomponent from './Slidecomponent'

const Maincontent = () => {
  return (
    <div>
      
      <Herocomponent/>
      <Aboutcomponent/>
      <Slidecomponent/>
      <Servicecomponent/>
      <Videocomponent/>
      <Faqcomponent/>
      <Contactform/>

    </div>
    
  )
}

export default Maincontent
