import React from 'react'

import Section1 from './Section1'
import Content from './Content'
import Aboutvideo from './Aboutvideo'

const Maincontent = () => {
  return (
    <div>
      <Section1/>
      <Content/>
      <Aboutvideo/>
   
      
      </div>
  )
}

export default Maincontent