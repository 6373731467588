  import React from 'react';
import Footercomponent from './Home/Footercomponent';



  const Footer = () => {

    return (
      <div>
        <Footercomponent/>
      </div>
    );
  };

  export default Footer;
