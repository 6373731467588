import React, { useState } from 'react';
import './Maincontent.css';
import data from '../api/Data';
import { useLocation } from 'react-router-dom';
import { Link } from 'react-router-dom';



const Maincontent = () => {
    const [selectedImageId, setSelectedImageId] = useState(null)

    console.log(data)
    const location = useLocation();
    const handleImageClick = (id) => {
        setSelectedImageId(id);
        console.log(`Image with ID ${id} clicked!`);

        const currentPath = location.pathname;
        window.location.href = `/Projectdetails?id=${id}`;
    };

    return (
        <div>
            <div className='container'>
                <div className='row section'>
                    <div className='col-12 col-md-12 col-lg-12 d-flex flex-column  '>
                        <div className='d-flex justify-content-between flex-wrap '>
                            <div className='content1 '>Urban Structures
                                <div className='content2'>Projects</div></div>
                            <div className='content3 '>
                                Welcome to Urban Structures' Projects Showcase. 
                                Explore our portfolio of innovative and inspiring designs that reflect our commitment to excellence in interior and exterior architecture, residential development, and 3D modeling. 
                                Each project exemplifies our dedication to creating spaces that seamlessly blend functionality, aesthetics, and sustainability.
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            <div className='container2 d-flex align-items-center justify-content-center'>
                {data.map((item, index) => (
                    <div className='item'>
                        <div className='border'></div>
                        <div className='title'>{item.title}</div>
                        <h6>{item.title_name}</h6>
                        <div className='image-container'>
                            <img src={item.Image} className='image1 img-fluid' style={{width:'100%',height:'100%'}} alt='' onClick={() => handleImageClick(item.id)} />
                            <p className="overlay-text">View Project Details</p>
                        </div>
                    </div>
                ))}

                {/* Pass selectedImageId as query parameter to the next page */}
                {selectedImageId && (
                    <Link to={`/Projectdetails?id=${selectedImageId}`}>View Details</Link>
                )}
            </div>

        </div>
        

    )
}

export default Maincontent