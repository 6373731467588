import React from 'react'

import { Container } from 'react-bootstrap';
import './Videocomponent.css'
import samplevideo from '../../Images/Home/Video.mp4';

const Videocomponent = () => {




    return (
        <div>

            <Container fluid className='Videocomponent d-flex flex-column align-items-center justify-content-start' style={{ padding: 'clamp(15px, 6vw, 100px)' }}>



            <h1 className='my-lg-5' style={{ fontSize: 'clamp(25px, 5vw, 60px)', fontWeight: 600, lineHeight: '120%', textTransform:'uppercase'}}>Watch our work here!</h1>

                <div className='video-container w-100 mt-lg-5 mt-3' >
                    <video controls className='video' style={{ width: 'clamp(250px, 80vw, 1328px)', height: '100%', background: 'linear-gradient(0deg, #D9D9D9 0%, #D9D9D9 100%), linear-gradient(0deg, rgba(0, 0, 0, 0.20) 0%, rgba(0, 0, 0, 0.20) 100%), linear-gradient(0deg, rgba(0, 0, 0, 0.20) 0%, rgba(0, 0, 0, 0.20) 100%)' }}>
                        <source src={samplevideo} type="video/mp4" />
                       
                    </video>
                </div>





            </Container>

        </div>


    )
}

export default Videocomponent
