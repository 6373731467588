import React from 'react';
import { NavLink } from 'react-router-dom';
import Container from 'react-bootstrap/Container';
import Nav from 'react-bootstrap/Nav';
import Navbar from 'react-bootstrap/Navbar';
import { Button } from 'react-bootstrap';
import './Navcomponent.css';

const Navcomponent = ({headerFontColor}) => {
    console.log(headerFontColor);
    const scrollToContactForm = () => {
        const contactFormElement = document.getElementById('contactForm');

        if (contactFormElement) {
            contactFormElement.scrollIntoView({ behavior: 'smooth' }, { duration: 900 });
        }
    };


    return (
        <Navbar expand="lg" className="navbar">
            <Container className="justify-content-between">
            <Navbar.Brand href="#" className={`navbar-brand ${headerFontColor ? 'black-color' : ''}`} >
                Urban Structures
                </Navbar.Brand>
                <Navbar.Toggle aria-controls="basic-navbar-nav" />
                <Navbar.Collapse id="basic-navbar-nav">
                    <Nav className="nav-group mx-auto">
                        <Nav className="mr-auto">
                            <Nav.Link as={NavLink} to="/" className={`nav-link  ${headerFontColor ? 'black-color' : ''}`}>
                                Home
                            </Nav.Link>
                            <Nav.Link as={NavLink} to="/Project" className={`nav-link  ${headerFontColor ? 'black-color' : ''}`} activeClassName="active-link" >
                                Project
                            </Nav.Link>
                            <Nav.Link as={NavLink} to="/AboutUs" className={`nav-link  ${headerFontColor ? 'black-color' : ''}`}>
                                About Us
                            </Nav.Link>
                        </Nav>
                    </Nav>
                    <Nav className="enquire-button w-auto  align-self-center justify-content-center" > 
                        <Button style={{ width:"180px", background: "black", color: "#FFF", textAlign: "center", fontFamily: "Metropolis", fontSize: "20px", fontStyle: "normal", fontWeight: 500, lineHeight: "120%" }} onClick={scrollToContactForm}>
                            Enquire now
                        </Button>
                    </Nav>

                </Navbar.Collapse>
            </Container>
        </Navbar>
    );
};

export default Navcomponent;