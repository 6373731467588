import React from 'react'
import './Footercomponent.css'
import { Container, Row, Col } from 'react-bootstrap'
import M1 from '../../Images/Instagram.svg'
import M2 from '../../Images/fb.svg'
import M3 from '../../Images/linkedin.svg'
import M4 from '../../Images/x.svg'
import M5 from '../../Images/Buildofy.png'
const Footercomponent = () => {
  return (
   <Container fluid className='Footercomponent' style={{ padding: 'clamp(15px, 6vw, 100px)' }}>
    <Row className='FootRowOne w-100' >
        <Col lg={6}>
            <h1>Urban Structures</h1>
            <p>Architecture Lorem ipsum dolor sit 
            amet, consectetur adipiscing elit, sed  do eiusmod.</p>
            <p className='mb-2'>Let’s connect</p>
            <div className='Socialmedia d-flex mb-5'>
                <img src={M1} alt=''/>
                <img src={M2} alt=''/>
                <img src={M3} alt=''/>
                <img src={M4} alt=''/>
                <img src={M5} alt=''/>
                

            </div>
        </Col>
        <Col lg={6} className='Explore pb-5 pb-lg-0'>
            <h2>Explore</h2>
           <ul className='px-0'>
            <a href='/' style={{textDecoration:'none'}}>
                <li className='cursor-pointer'>Home</li>
            </a>
            <a href='/AboutUs' style={{textDecoration:'none'}}>
                <li className='cursor-pointer'>About Us</li>
            </a>
            <a href='/Project' style={{textDecoration:'none'}}>
                <li className='cursor-pointer'>Our Projects</li>
            </a>
           </ul>
        </Col>

    </Row>
   
    <Row className='FootRowTwo w-100 h-30 pb-0 mb-0' >
    <Col lg={6} className='d-flex justify-content-center justify-content-lg-start px-0'>
            <h5>Copyrights@ 2023 All rights reserved </h5>
        </Col>
        <Col lg={6} className='d-flex justify-content-center justify-content-lg-end px-0'>
        <div className='Policy d-flex justify-content-lg-between justify-content-around'>
        <h6>Privacy Policy</h6>
        <h6>Terms & Conditions</h6>
        </div>
        </Col>

    </Row>

    
   </Container>
  )
}

export default Footercomponent